<template>
  <Page color="primary" title="intern.gymkirchenfeld.ch">
    <template slot="before">
      <Modules />
    </template>
    <template v-if="help" slot="help">{{ help }}</template>
  </Page>
</template>

<script>
import { bus } from "@/main";
import Modules from "@/components/modules/";

export default {
  name: "Intern",
  components: { Modules },
  props: ["help"],
  data() {
    return { count: 1 };
  },
  created() {
    bus.$on("menu", () => {
      this.count = this.count + 1;
    });
  },
};
</script>
