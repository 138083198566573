<template>
  <div class="mt-4">
    <v-menu
      v-model="menu"
      :disabled="loading"
      offset-y
      :close-on-content-click="false"
      close-on-click
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab fixed bottom right class="ml-2" v-bind="attrs" v-on="on">
          <v-icon>mdi-cogs</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item
            v-for="item in itemsMenu"
            :key="'menu' + item.name"
            :disabled="saving || item.forced"
            :class="item.shown ? 'primary--text' : ''"
            @click="item.forced ? '' : (item.shown = !item.shown)"
          >
            <v-list-item-action>
              <v-switch
                :disabled="item.forced"
                inset
                :input-value="item.shown"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.shown ? "wird angezeigt" : "wird nicht angezeigt"
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon :color="item.shown ? 'primary' : ''">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="menu = false">
            Schliessen
          </v-btn></v-card-actions
        >
      </v-card>
    </v-menu>

    <masonry :gutter="16" :cols="cols">
      <v-card
        v-for="item in itemsFiltered"
        :key="'module' + item.name"
        v-show="item.ready"
        class="mb-4"
      >
        <v-system-bar dark>
          <v-icon small>{{ item.icon }}</v-icon>
          <span>{{ item.name }}</span>
          <v-spacer></v-spacer>
          <v-icon v-if="item.dev">mdi-bug</v-icon>
          <v-btn icon small v-if="!item.forced" @click="item.shown = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-system-bar>
        <component
          :ref="item.name"
          @loaded="item.ready = true"
          @close="item.ready = false"
          v-bind:is="item.component"
        ></component>
      </v-card>
    </masonry>
  </div>
</template>
<script>
import items from "./modules.js";
export default {
  name: "Modules",
  data() {
    return {
      autoUpdateIntervals: [],
      items: items,
      loading: false,
      menu: false,
      saving: false,
    };
  },
  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
        case "md":
          return 3;
        case "lg":
          return 3;
        case "xl":
          return 4;
      }
      return 3;
    },
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          (item.shown || item.forced) &&
          (!item.role || this.$_hasRole(item.role)) &&
          (!item.dev || !this.isProd())
      );
    },
    itemsMenu() {
      return this.items.filter(
        (item) =>
          !item.forced &&
          (!item.role || this.$_hasRole(item.role)) &&
          (!item.dev || !this.isProd())
      );
    },
    itemsHidden() {
      return this.items
        .filter((item) => !item.shown)
        .map((el) => el.name)
        .join(",");
    },
  },
  methods: {
    updateOnFocus() {
      if (navigator.onLine) {
        this.itemsFiltered
          .filter((el) => el.updateOnFocus)
          .forEach((el) => {
            this.$refs[el.name][0].fetchData();
          });
      } else {
        this.showOffline();
      }
    },
    onOnline() {
      this.showOnline();
      this.updateOnFocus();
    },
    showOnline() {
      this.$root.showNotification(
        "<strong>Du bist wieder online!</strong><br>Daten werden aktualisiert...",
        "mdi-access-point-network",
        "success"
      );
    },
    showOffline() {
      this.$root.showNotification(
        "Daten konnten nicht aktualisiert werden...<br><strong>Du bist offline!</strong>",
        "mdi-access-point-network-off",
        "danger"
      );
    },
  },
  watch: {
    async itemsHidden(newVal) {
      this.saving = true;
      await this.apiPost({
        resource: "app/settings",
        data: { key: "ModulesHidden", value: newVal },
      });
      this.saving = false;
    },
  },
  async created() {
    this.loading = true;
    const data = (
      await this.apiList({
        resource: "app/settings",
        query: "key=ModulesHidden",
      })
    ).value;
    if (data) {
      const items = data.split(",");
      for (const item of items) {
        const found = this.items.find((el) => el.name === item);
        if (found) {
          found.shown = false;
        }
      }
    }
    this.loading = false;
  },
  mounted() {
    window.addEventListener("focus", this.updateOnFocus);
    window.addEventListener("online", this.onOnline);

    this.itemsFiltered
      .filter((el) => el.autoUpdateInterval)
      .forEach((el) => {
        this.autoUpdateIntervals.push(
          window.setInterval(() => {
            if (navigator.onLine) {
              this.$refs[el.name][0].fetchData();
            } else {
              this.showOffline();
            }
          }, el.autoUpdateInterval * 60 * 1000)
        );
      });
  },
  beforeDestroy() {
    window.removeEventListener("focus", this.updateOnFocus);
    window.removeEventListener("online", this.onOnline);

    for (const interval of this.autoUpdateIntervals) {
      window.clearInterval(interval);
    }
    this.autoUpdateIntervals = [];
  },
};
</script>
